import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { motion, useInView } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bgvideo from "../assets/bg.mp4";
import logo from "../assets/logo.png";
import logo1 from "../assets/websites/logo1.png";
import logo2 from "../assets/websites/logo2.png";
import logo3 from "../assets/websites/logo3.png";
import logo4 from "../assets/websites/logo4.png";
import logo5 from "../assets/websites/logo5.png";
import logo6 from "../assets/websites/logo6.png";
import logo7 from "../assets/websites/logo7.jpg";
import logo8 from "../assets/websites/logo8.png";
import logo9 from "../assets/websites/logo9.png";
import logo10 from "../assets/websites/logo10.jpg";
import logo11 from "../assets/websites/logo11.png";
import logo12 from "../assets/websites/logo12.jpg";

const Popup = ({ onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
    <div className="absolute inset-0 z-40 bg-gradient-to-br via-[#1f2937] from-[#1a202c] to-[#18cb96] animate-gradient" />
    <motion.div
      className="relative bg-gradient-to-tl via-[#1f2937] from-[#1a202c] to-[#18cb96]  p-8 mx-8 rounded-lg shadow-2xl text-center z-50"
      initial={{ scale: 0.5, rotateY: 90, opacity: 0 }}
      animate={{ scale: 1, rotateY: 0, opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <motion.h2
        className="text-3xl font-extrabold mb-6 text-[#18cb96]"
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.6 }}
      >
        🚀 Ready to Elevate Your Business Beyond Limits?
      </motion.h2>
      <motion.button
        onClick={onClose}
        className="bg-[#18cb96] text-white px-8 py-3 rounded-full font-bold shadow-lg hover:shadow-xl hover:scale-105 transition-transform transform hover:rotate-2 focus:outline-none focus:ring-4 focus:ring-green-200"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        Yes, Take Me There!
      </motion.button>
    </motion.div>
  </div>
);


const ScrollPrompt = () => (
  <motion.div
    className="absolute bottom-4 inset-x-0 flex justify-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1, delay: 2 }}
  >
    <div className="text-white text-sm animate-bounce">
      Scroll down to explore ↓
    </div>
  </motion.div>
);

const AnimatedStat = ({ number, add, description, delay }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      const duration = 1500;
      const increment = number / (duration / 16);
      let frame = 0;

      const interval = setInterval(() => {
        frame += increment;
        if (frame >= number) {
          setCurrentValue(number);
          clearInterval(interval);
        } else {
          setCurrentValue(Math.ceil(frame));
        }
      }, 16);

      return () => clearInterval(interval);
    }
  }, [isInView, number]);

  return (
    <motion.div
      ref={ref}
      whileHover={{ scale: 1.1 }}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 50 }}
      transition={{ duration: 0.5, delay }}
      className="flex flex-col items-center text-center bg-[#1f2937] p-6 rounded-lg shadow-lg w-[250px] sm:w-[300px]"
    >
      <div className="text-5xl font-bold text-[#18cb96] items-center flex flex-row">
        {currentValue}
        <p>{add}</p>
      </div>
      <p className="text-lg text-[#e5e7eb] mt-4">{description}</p>
    </motion.div>
  );
};

const StatisticsSection = () => {
  const stats = [
    { number: 20, add: "%", description: "Increase in Sales" },
    { number: 15000, add: "+", description: "Customer Visits" },
    { number: 15, add: "+", description: "Businesses with Websites" },
  ];

  return (
    <div className="py-16 bg-gradient-to-r from-[#1e293b] to-[#374151] text-white">
      <motion.h2
        className="text-[#18cb96] text-4xl font-bold pb-8"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        The Numbers
      </motion.h2>
      <div className="flex flex-wrap justify-center items-center gap-8 px-4">
        {stats.map((stat, index) => (
          <AnimatedStat
            key={index}
            number={stat.number}
            description={stat.description}
            add={stat.add}
            delay={index * 0.3}
          />
        ))}
        <div></div>
      </div>
    </div>
  );
};

const LogoCarousel = () => {
  const logos = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
  ];

  const repeatedLogos = [...logos, ...logos, ...logos];

  return (
    <div className="py-16 bg-gradient-to-br from-[#2c2c2c] to-[#1f2937]">
      <h2 className="text-[#18cb96] text-3xl font-bold pb-8 px-4">
        Businesses We've Helped
      </h2>
      <div className="overflow-hidden relative">
        <motion.div
          className="flex space-x-6 animate-scroll"
          whileHover={{ animationPlayState: "paused" }}
          style={{
            display: "flex",
          }}
        >
          {repeatedLogos.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-20 sm:h-28 object-contain mx-4"
            />
          ))}
        </motion.div>
      </div>

      <style jsx>{`
        .animate-scroll {
          animation: scrolling 30s linear infinite;
        }
        @keyframes scrolling {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
};

const WhyGrowifySection = () => (
  <div className="relative h-auto sm:py-16 bg-[#373643]">
    <div className="flex flex-col-reverse sm:flex-row justify-center items-start sm:space-x-8 px-6 sm:px-20">
      <div className="relative flex flex-col sm:w-1/2 mt-8 sm:mt-0 space-y-4">
        <div className="relative pt-6 sm:pt-4">
          <h1 className="text-[#18cb96] text-left text-4xl font-bold py-8 underline">
            Why Growify?
          </h1>
          <p className="text-white text-lg leading-relaxed text-left pb-4">
            In today’s rapidly changing digital world, having a website is
            essential for any business, large or small. It increases your
            brand's visibility, helping potential customers find you 24/7, while
            also building credibility and trust for your brand. Growify's
            professional design paired with the detailed information we provide
            about your products or services ensure customers are informed,
            leading to more sales for your business.
            <br></br>
            Furthermore, a website built by Growify allows for improved customer
            engagement through easy communication channels and provides
            cost-effective marketing opportunities due to our flexible pricing.
            Lastly, it offers a competitive advantage in comparison to other
            business without websites, allowing your business to reach a greater
            audience and increase sales. Simply put, a website is crucial for
            your business's growth and success.
          </p>
        </div>
      </div>
      <div className="relative flex flex-col w-full sm:w-1/2 space-y-4">
        <motion.div
          className="relative w-full"
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <img
            src={bg2}
            alt="Growify Example"
            className="w-full h-auto object-cover sm:p-2 pt-6"
          />
        </motion.div>
      </div>
    </div>
  </div>
);

const TestimonialsSection = () => {
  const testimonials = [
    {
      quote:
        "Growify & Aarnav have been a huge help in promoting business to my small construction company, driving in customers I had thought would be much harder to come by. His services have allowed me to focus on closing and executing jobs. He is attentive and continues to make timely updates, uploading photos as fast as I put in requests for his assistance. Truly a professional excelling in web design and account management services. He is studying and will surely be part in future innovation and upgrades in the industry.",
      author: "- Cedar Carpentry",
    },
    {
      quote:
        "Aarnav & Growify did a wonderful job designing our website,. It was stylish, user-friendly, and works great on both desktop and mobile. During the process of working together, he was attentive, insightful, thorough, on time and efficient. We look forward to working with them again on future web design projects.",
      author: "- CC Creations",
    },
    {
      quote:
        "Aarnav & Growify did an outstanding job creating a website for my business. He took the time to genuinely understood my vision and turned it into a clean, professional, and user-friendly site. Throughout the process I was impressed by his creativity, attention to detail, and how smoothly the entire process went. I highly recommend Aarnav and Growify's development services.",
      author: "- Sunflower Childcare Center",
    },
    {
      quote:
        "I highly recommend Aarnav & Growify for any web development project. He designed my website and it met all my needs. Aarnav & Growify is responsive when ever I would contact him with any adjustments and a pleasure to work with.",
      author: "- Stud TV Installs",
    },
    {
      quote:
        "Growify & Aarnav did an amazing job on my website! Growify were professional, listened to my ideas, and created exactly what I wanted. Highly recommend!",
      author: "- Kuwento Barbershop",
    },
    {
      quote: "Aarnav & Growify were great to work with during the whole process of creating the website. Communication was solid and he was really patient with my schedule which I really appreciated. I now have a website I'm happy to represent my business.",
      author: "- NYAJCS",
    },
  ];

  return (
    <div className="py-16 bg-gradient-to-r from-[#2d3748] to-[#1a202c] text-white">
      <h2 className="text-[#18cb96] text-4xl font-bold pb-8">
        What Our Clients Say
      </h2>
      <div className="flex flex-wrap justify-center gap-8 px-4">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            className="bg-[#1f2937] p-6 rounded-lg shadow-md w-[300px] sm:w-[350px] text-center"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <p className="text-lg text-[#e5e7eb] italic mb-4">
              "{testimonial.quote}"
            </p>
            <p className="text-[#18cb96] font-bold">{testimonial.author}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const Home = () => {
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="text-center poppins">
      {showPopup && <Popup onClose={handleClosePopup} />}
      {!showPopup && (
        <>
          <motion.div
            className="h-screen flex flex-col items-center justify-center relative bg-gradient-to-br from-[#18cb96] via-[#1e1e1e] to-[#373643] overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <ScrollPrompt />
            <div className="z-10 text-center text-white px-4">
              <h1 className="text-5xl md:text-6xl font-bold leading-tight">
                Growifyme
              </h1>
              <h3 className="text-lg md:text-2xl mt-3 font-medium">
                Boost your online presence & sales with Growify.
              </h3>
              <div className="mt-6 space-y-4">
                <a
                  href="sms:+14255200792"
                  className="bg-[#18cb96] text-white px-5 py-3 rounded-lg font-bold flex items-center justify-center text-lg md:text-xl shadow-md hover:shadow-lg transition-all"
                >
                  📞 (425) 520 0792
                </a>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <a
                    href="mailto:contactgrowifyme@gmail.com"
                    className="bg-[#373643] text-[#18cb96] px-5 py-3 rounded-lg font-bold text-base md:text-lg shadow-md hover:shadow-lg block"
                  >
                    📧 contactgrowifyme@gmail.com
                  </a>
                </motion.div>
              </div>
            </div>
          </motion.div>
          <TestimonialsSection />
          <StatisticsSection />
          <LogoCarousel />
          <WhyGrowifySection />
        </>
      )}
    </div>
  );
};

export default Home;
