import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import bg2 from "../assets/bg5.jpg";

const images = [bg2];

const Internships = () => {
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-gradient-to-br from-[#1e293b] to-[#6f6e6e] min-h-screen text-center poppins">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#373643] bg-opacity-50 flex flex-col items-center justify-center text-center">
          <h1 className="text-[#18cb96] text-4xl font-bold mb-4 mt-12">
            Internship Opportunities
          </h1>
          <p className="text-white text-lg max-w-2xl px-6">
            Gain real world experience and grow your skills with our internship
            program. We offer opportunities in business and computer science.
          </p>
        </div>
      </motion.div>

      <div className="container mx-auto px-4 pt-8">
        <h2 className="text-2xl text-center underline underline-offset-2 font-bold mb-6 text-[#18cb96]">
          Internship Tracks
        </h2>
        <p className="text-white text-lg max-w-2xl mx-auto pb-8">
          We offer two internship tracks for high school students across the
          world. Our internships are remote and flexible to fit your schedule.
          We are based in Seattle, so locality helps, but is not a requirement at
          all. By signing up for our internship program, you will gain real
          world experience and grow your skills while having the opportunity to
          gain further opportunities with us based off your performance. Sign up
          using the form below. Note: All internships are unpaid, but team
          members may recieve stipends, merch and other benefits (not
          guarenteed).
        </p>

        <h3 className="text-2xl text-[#18cb96] font-bold mb-4">Use this   
        <a href="https://forms.gle/4QByXxwGKuY5Lpkp6" className="text-white hover:underline"> link </a>
           to apply</h3>

        <div className="flex flex-col items-center space-y-8">
          <motion.div
            className="bg-[#373643] text-white px-6 py-4 rounded-lg shadow-lg max-w-lg text-center"
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          >
            <h3 className="text-xl font-semibold text-[#18cb96] mb-2">
              Business Internship
            </h3>
            <p>
              Work with our team on business development, marketing, and client
              relations. Gain real world experience in social media, sales, and
              more.
            </p>

            <div className="mt-4 text-center">
              <h4 className="text-lg font-semibold text-[#18cb96] mb-1">
                Responsibilities:
              </h4>
              <ul className="list-disc list-inside text-smm text-left">
                <li>Recruit and built relationships with clients</li>
                <li>Maintain Social Media for growifyme and clients</li>
                <li>Write business reports</li>
                <li>Collaborate in a team</li>
              </ul>
            </div>
          </motion.div>
          <motion.div
            className="bg-[#373643] text-white px-6 py-4 rounded-lg shadow-lg max-w-lg text-center"
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          >
            <h3 className="text-xl font-semibold text-[#18cb96] mb-2">
              Computer Science Internship
            </h3>
            <p>
              Work on building websites, developing new innovative software, and
              AI projects. Build your skills and learn while working on real
              world projects.
            </p>

            <div className="mt-4 text-center">
              <h4 className="text-lg font-semibold text-[#18cb96] mb-1">
                Responsibilities:
              </h4>
              <ul className="list-disc list-inside text-smm text-left">
                <li>Work on building websites</li>
                <li>Develop new software</li>
                <li>Work on AI projects</li>
                <li>Collaborate in a team</li>
              </ul>
            </div>
          </motion.div>
        </div>

        <div className="text-center mt-12 pb-8">
          <p className="text-white text-lg">Questions? Email us at:</p>
          <a
            href="mailto:internships@growify.com"
            className="text-[#18cb96] text-xl font-semibold mt-2 block"
          >
            📧 contactgrowifyme@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Internships;
