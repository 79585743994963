import { React, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo.png';

const Header = () => {
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header
            className={`text-[#18cb96] poppins top-0 left-0 right-0 fixed z-50 transition-colors duration-300 ${scrolled ? 'bg-[#373643] bg-opacity-90 shadow-md' : 'bg-transparent'}`}
        >
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <Link to="/" className="flex items-center">
                    <img src={logo} alt="Logo" className="h-12 w-12 mr-2 rounded-none" />
                    <span className="text-2xl font-bold drop-shadow-md sm:text-3xl">Growifyme</span>
                </Link>
                <nav className="hidden md:flex space-x-4 sm:text-lg">
                    <Link to="/" className={`hover:underline ${location.pathname === '/' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Home</Link>
                    <Link to="/services" className={`hover:underline ${location.pathname === '/services' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Services</Link>
                    <Link to="/services/website" className={`hover:underline ${location.pathname === '/services/website' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Websites</Link>
                    <Link to="/contact" className={`hover:underline ${location.pathname === '/contact' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Contact</Link>
                    <Link to="/intern" className={`hover:underline ${location.pathname === '/intern' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Intern</Link>
                </nav>
                <button className="md:hidden" onClick={toggleDrawer}>
                    <FaBars size={30}/>
                </button>
            </div>
            {drawerOpen && (
                <div className="fixed inset-0 bg-[#373643] bg-opacity-75 z-50 md:hidden">
                    <div className="absolute top-0 right-0 p-4">
                        <button onClick={toggleDrawer}>
                            <FaTimes className="text-18cb96" size={25}/>
                        </button>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full space-y-4 text-[#18cb96]">
                        <Link to="/" onClick={toggleDrawer} className={`text-xl ${location.pathname === '/' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Home</Link>
                        <Link to="/services" onClick={toggleDrawer} className={`text-xl ${location.pathname === '/services' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Services</Link>
                        <Link to="/services/website" onClick={toggleDrawer} className={`text-xl ${location.pathname === '/services/website' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Websites</Link>
                        <Link to="/contact" onClick={toggleDrawer} className={`text-xl ${location.pathname === '/contact' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: '#18cb96' }}>Contact</Link>
                    </div>
                </div>
            )}
        </header>
    );
}

export default Header;
