import React, { useState } from "react";
import { motion } from "framer-motion";
import bgimage from "../../assets/dt.jpg";
import llm from "../../assets/llm.jpg";
import dtt from "../../assets/dtt.jpg";
import gr from "../../assets/gr.png";
import { Link } from "react-router-dom";

const stats = [
  { label: "Average Return per Dollar Spent w/Social Media", value: "$2.80" },
  { label: "Businesses using Digital Tools report Growth", value: "87%" },
  { label: "Users purchase from Social Media Ads", value: "76%" },
  { label: "Businesses using Digital Tools", value: "93.79%" },
];

const services = [
  {
    title: "Customer Assistance Tools",
    description:
      "With AI let us build Customer Assistance Chatbots to answer all of your customer's questions",
    image: llm,
  },
  {
    title: "Business Digitization",
    description: "Get you business on Google Maps, Yelp and other digital touchpoints with us picking the best photos.",
    image: dtt,
  },
  {
    title: "Review Optimization",
    description:
      "Improve your reviews & ratings online through out filtering process that ensures you only recieve the best reviews.",
    image: gr,
  },
];

const ScrollPrompt = () => (
  <motion.div
    className="absolute bottom-4 inset-x-0 flex justify-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1, delay: 2 }}
  >
    <div className="text-white text-sm animate-bounce">
      Scroll down to discover more ↓
    </div>
  </motion.div>
);

const DigitalTools = () => {
  return (
    <div className="flex flex-col min-h-screen poppins bg-gradient-to-r from-[#1e293b] to-[#6f6e6e]">
      <motion.div
        className="relative sm:h-[calc(100vh-64px)] h-[80vh] w-full bg-cover bg-center"
        style={{ backgroundImage: `url(${bgimage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <ScrollPrompt />

        <div className="absolute inset-0 bg-[#373643] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#18cb96] sm:text-5xl text-4xl pt-24 font-bold mb-4 px-6">
            Digital Tools to Supercharge Your Business
          </h1>
          <p className="text-white text-lg mb-6 px-4 text-center">
            Leverage social media, analytics, and content tools to grow your
            online presence and business.
          </p>
          <a
            href="sms:+14255200792"
            className="bg-[#373643] text-[#18cb96] px-4 py-2 mb-2 rounded font-bold flex items-center space-x-1 text-lg md:text-xl"
          >
            <span>📞</span>
            <span>(425) 520 0792</span>
          </a>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
          >
            <a
              href="mailto:contactgrowifyme@gmail.com"
              className="bg-[#373643] text-[#18cb96] px-4 py-2 sm:px-6 sm:py-3 rounded font-bold text-base sm:text-lg block text-center"
            >
              📧 contactgrowifyme@gmail.com
            </a>
          </motion.div>
        </div>
      </motion.div>

      <div className="container mx-auto px-4 py-12 text-[#18cb96]">
        <h2 className="text-3xl font-bold text-center mb-8 text-[#18cb96]">
          Our Digital Tools & Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <Link
              key={index}
              to={service.link}
              className="shadow-lg rounded-lg bg-gradient-to-br from-[#1e293b] to-[#6f6e6e] block"
            >
              <h3 className="text-xl font-bold text-center text-[#18cb96] mb-4 pt-4 px-4">
                {service.title}
              </h3>
              <p className="text-center mb-4 px-4">{service.description}</p>
              <div className="w-full h-64">
                <motion.img
                  src={service.image}
                  alt={`Service ${index}`}
                  className="w-full h-full object-cover"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="bg-gradient-to-tl from-[#1e293b] to-[#6f6e6e] py-12 text-[#18cb96]">
       
        <div className="container mx-auto px-6 py-12 text-center">
          <motion.h2
            className="text-3xl font-bold text-[#18cb96] mb-6"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Transform Your Business with Digital Tools
          </motion.h2>
          <motion.p
            className="text-[#18cb96] text-lg max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            At Growify, we offer a range of digital tools to help your business
            thrive. From AI Chatbots and Review Optimization to outstanding
            Business Digitization, we provide everything you need to grow your online
            presence, reduce conversion time, and achieve everlasting success.
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default DigitalTools;
