import React from 'react';
import { FaEnvelope, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-[#18cb96] text-[#373643] py-4 poppins">
            <div className="container mx-auto px-4">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="text-center sm:text-left mb-4 sm:mb-0">
                        <p>&copy; 2025 Growify</p>
                        <p>Phone: (425) 520-0792</p>
                    </div>
                    <div className="flex space-x-4">
                        <a href="mailto:contactgrowifyme@gmail.com" className="hover:text-gray-200">
                            <FaEnvelope size={24} />
                        </a>
                        <a href='https://www.instagram.com/growifyme/' target='_blank' rel='noreferrer' className="hover:text-gray-200">
                            <FaInstagram size={24} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
