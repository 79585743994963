import React, { useState, useEffect } from "react";
import {
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaInstagram,
} from "react-icons/fa";
import { motion } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import { FaShareNodes } from "react-icons/fa6";

const images = [bg2];

const Contact = () => {
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Growify",
          text: "Check out Growify for all your construction needs!",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div className="bg-gradient-to-br from-[#1e293b] to-[#6f6e6e] min-h-screen text-center poppins">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#373643] bg-opacity-50 flex flex-col items-center justify-center text-center">
          <h1 className="text-[#18cb96] text-4xl font-bold mb-4 mt-12">
            Contact Us
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="mb-4"
          >
            <a
              href="sms:+14255200792"
              className="bg-[#373643] text-[#18cb96] px-4 py-2 rounded font-bold flex items-center space-x-2"
              aria-label="Phone Number"
            >
              <FaPhoneAlt />
              <span>(425) 520-0792</span>
            </a>
          </motion.div>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
          >
            <a
              href="mailto:contactgrowifyme@gmail.com"
              className="bg-[#373643] text-[#18cb96] px-4 py-2 sm:px-6 sm:py-3 rounded font-bold text-base sm:text-lg"
              aria-label="Email Address"
            >
              📧 contactgrowifyme@gmail.com
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 pt-8">
        <h2 className="text-2xl text-center underline underline-offset-2 font-bold mb-4 text-[#18cb96]">
          Contact Info:
        </h2>
        <div className="flex flex-col items-center space-y-4 mb-8">
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaPhoneAlt
              className="text-[#18cb96]"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="sms:+14255200792"
              className="bg-[#18cb96] text-[#373643] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              425-520-0792
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaShareNodes
              className="text-[#18cb96]"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="#"
              onClick={handleShare}
              className="bg-[#18cb96] text-[#373643] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              Share this website
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaMapMarkerAlt
              className="text-[#18cb96]"
              style={{ verticalAlign: "middle" }}
            />
            <p className="text-lg text-[#18cb96]">Serving the local Seattle Area & Beyond!</p>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaEnvelope
              className="text-[#18cb96]"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="mailto:contactgrowifyme@gmail.com"
              className="bg-[#18cb96] text-[#373643] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              contactgrowifyme@gmail.com
            </a>
          </motion.div>
        </div>
        <div className="flex justify-center space-x-4">
          <a
            href="https://www.instagram.com/growifyme/"
            target="_blank"
            rel="noreferrer"
            className="text-[#18cb96] hover:text-white"
          >
            <FaInstagram size={40} className="mb-4 "/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
