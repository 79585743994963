import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Website from './pages/services/Website';
import Contact from './pages/Contact';
import SocialMedia from './pages/services/SocialMedia';
import DigitalTools from './pages/services/DigitalTools';
import Intern from './pages/Intern';

function App() {
  return (
    <Router>
      <div className='flex flex-col min-h-screen'>
        <Header />
        <main className='flex-grow'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/services/social-media' element={<SocialMedia />} />
            <Route path='/services/website' element={<Website />} />
            <Route path='/services/digital-tools' element={<DigitalTools />} />
            <Route path='/intern' element={<Intern />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
