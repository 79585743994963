import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import bg1 from "../assets/bg1.mp4";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import sm from "../assets/sm.jpg";

const Services = () => {
  const images = [bg2, bg3];
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  const pricingTiers = [
    { label: "Struggling Business", setupFee: "$25", monthlyFee: "Free" },
    { label: "Small Business", setupFee: "$100", monthlyFee: "$5" },
    { label: "Established Business", setupFee: "$200", monthlyFee: "$10" },
    { label: "Successful Business", setupFee: "$300", monthlyFee: "$20" },
    {
      label: "Other Solutions",
      setupFee: "Custom",
      monthlyFee: "Free as add-ons",
    },
  ];

  

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="text-center poppins bg-gradient-to-br from-[#18cb96] via-[#1e1e1e] to-[#373643]">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bg1})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#373643] bg-opacity-50 flex items-center justify-center flex-col">
          <motion.h1
            className="text-[#18cb96] text-4xl font-bold mb-4"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            Our Services
          </motion.h1>
          <a
            href="sms:+14255200792"
            className="bg-[#373643] text-[#18cb96] px-4 py-2 mb-2 rounded font-bold flex items-center space-x-1 text-lg md:text-xl"
          >
            <span>📞</span>
            <span>(425) 520 0792</span>
          </a>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
          >
            <a
              href="mailto:contactgrowifyme@gmail.com"
              className="bg-[#373643] text-[#18cb96] px-4 py-2 sm:px-6 sm:py-3 rounded font-bold text-base sm:text-lg block text-center"
            >
              📧 contactgrowifyme@gmail.com
            </a>
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        className="bg-[#373643] py-10 px-4 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <motion.h2
          className="text-[#18cb96] text-3xl font-bold mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          Pay What You Can Business Model
        </motion.h2>
        <motion.h3
          className="text-[#18cb96] text-2xl font-bold mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.2, ease: "easeOut" }}
        >
          Example Prices:
        </motion.h3>

        <div className="overflow-x-auto">
          <table className="table-auto w-full text-left border-collapse">
            <thead className="bg-[#282828] text-[#18cb96] uppercase text-lg sm:text-xl">
              <tr>
                <th className="px-4 py-2">Shape of Business</th>
                <th className="px-4 py-2">Yearly Domain & Business Fee</th>
                <th className="px-4 py-2">Monthly Managing Fee</th>
              </tr>
            </thead>
            <tbody className="text-white text-md sm:text-lg">
              {pricingTiers.map((tier, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#444444]" : "bg-[#333333]"
                  }`}
                >
                  <td className="px-4 py-2">{tier.label}</td>
                  <td className="px-4 py-2">{tier.setupFee}</td>
                  <td className="px-4 py-2">{tier.monthlyFee}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </motion.div>

      <motion.div
        className="max-w-4xl mx-auto px-4 pt-4 pb-10 "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4 text-white">
          <ServiceCard
            title="Website Building"
            backgroundImage={bg2}
            path="/services/website"
          />
          <ServiceCard
            title="Social Media Marketing"
            backgroundImage={sm}
            path="/services/social-media"
          />
          <ServiceCard
            title="Custom Digital Tools & Solutions"
            backgroundImage={bg3}
            path="/services/digital-tools"
          />
        </div>
      </motion.div>
    </div>
  );
};

const ServiceCard = React.forwardRef(
  ({ title, backgroundImage, path }, ref) => {
    const { ref: inViewRef, inView } = useInView({
      triggerOnce: true,
      threshold: 0.2,
    });

    const cardStyle = {
      backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    };

    return (
      <Link to={path} className="block" ref={inViewRef}>
        <motion.div
          className="rounded-md p-4 flex flex-col items-center justify-center text-center transition duration-300 h-40 w-full"
          style={cardStyle}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-2">{title}</h2>
        </motion.div>
      </Link>
    );
  }
);

export default Services;
